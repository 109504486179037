import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function ManageCandidates() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="6" />

                    </div>
                    <div className="w-3/4">
                      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                      Manage your candidates
                      </h2>               
                      <br /><br />       
                      This section shows you all your candidates: names, the tests they have taken and how they did, and displays their unique pin code. You can filter the candidates and search for specific people.
                      <br /><br />       
                      <img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/candidates-list.png" alt="" />
                      <br /><br />       

                      Adding new candidates is easy.
                      <br /><br />       

First step: enter the candidate's name and email address. If notifications are turned on, Skillmeter will send an email to the candidates with their PIN code and link to the test center.
<br /><br />       

Second step: assign the test for that candidate. You can assign one or more tests. If you select more tests you have the option to group them in one session or split them in individual sessions.
<br /><br />       

You also have the option to require test-takers to give you feedback (test difficulty and free comments) on each test after they complete them.
<br /><br />       

<img className="w-full max-w-md" src="https://d2mwjjtum67wgo.cloudfront.net/Content/Public3/img/tour/new-candidate.png" alt="" />


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}